<template>
  <div class="image-slider" :class="customClass">
    <Swiper @swiper="setThumbsSwiper" :slidesPerView="10" :spaceBetween="10" :breakpoints="{
      '@0.20': {
        slidesPerView: 4,
        spaceBetween: 8,
      },
      '@1.00': {
        slidesPerView: 8,
        spaceBetween: 12,
      },
      '@1.50': {
        slidesPerView: 12,
        spaceBetween: 16,
      },
    }" :modules="modules" class="swiperItems container">
      <SwiperSlide v-for="(item, i) in items" :key="i">
        <img :src="item.url" :alt="`item-url-${i}`" />
      </SwiperSlide>
    </Swiper>
    <Swiper :style="{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }" :loop="true"
      :spaceBetween="10" :navigation="true" :thumbs="{ swiper: thumbsSwiper }" :modules="modules"
      class="mySwiper2 container">
      <SwiperSlide v-for="(item, i) in items" :key="i">
        <img :src="item.url" :alt="`item-url-${i}`" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, FreeMode, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import '~/assets/scss/partials/_image-slider.scss';

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      thumbsSwiper: null,
      modules: [Navigation, FreeMode, Thumbs]
    }
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  }
});

</script>

<style lang="scss" scoped>
@use '@/assets/scss/partials/_image-slider.scss';
</style>