<template>
  <section id="team-section">
    <div class="section-title container">
      <div class="title-content">
        <span class="heading-2">Freuen uns, Ihnen
          <span class="heading-2 text-1"> Unser Team</span>
          <span class="heading-2"> vorzustellen</span>
        </span>
      </div>
      <span class="title-description paragraph normal">
        Unser erfahrenes Team steht bereit, um Ihre Projekte professionell und effizient umzusetzen. Mit Fachkenntnissen
        und Leidenschaft garantieren wir erstklassige Ergebnisse.
      </span>
      <BauButton btnSuffix="kostenlos" btnText="Angebot einholen" @click="emits('showModal', true)"/>
    </div>
    <div class="section-content">
      <img src="/img/welcome/team-section-bg.jpg" alt="team-jpg">
    </div>
  </section>
</template>

<script setup>
import BauButton from '~/components/ui/BauButton.vue';
const emits = defineEmits(['showModal'])

</script>

<style lang="scss" scoped>
@use '~/assets/scss/partials/welcome/_team-section.scss'
</style>
