<template>
  <ModalWindow class="modal-slider--swiper" ref="modal" :name="modalName">
    <div class="content_header container">
      <Swiper class="swiperItems" @swiper="setThumbsSwiper" :slidesPerView="10" :spaceBetween="10"
        :breakpoints="breakpoints" :modules="modules" :loop="true">
        <SwiperSlide v-for="(item, i) in works" :key="i">
          <img :src="item.url" :alt="`item-url-${i}`" />
        </SwiperSlide>
      </Swiper>
      <IconButton @click="close" @mouseover="iconName = 'close'" @mouseleave="iconName = 'close-hover'"
        :name="iconName">
      </IconButton>
    </div>
    <Swiper :style="{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }" :loop="true"
      :spaceBetween="10" :navigation="true" :thumbs="{ swiper: thumbsSwiper }" :modules="modules"
      class="mySwiper2 container">
      <SwiperSlide v-for="(item, i) in works" :key="i">
        <img :src="item.url" :alt="`item-url-${i}`" />
      </SwiperSlide>
    </Swiper>
  </ModalWindow>
</template>

<script>
import ModalWindow from '~/components/ui/ModalWindow.vue';
import ImageSlider from '~/components/ui/ImageSlider.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, FreeMode, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
export default defineComponent({
  components: {
    ModalWindow,
    ImageSlider,
    Swiper,
    SwiperSlide
  },
  props: {
    modalName: {
      type: String,
      default: 'galleryModal'
    },
    works: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isOpen: false,
      iconName: 'close-hover',
      thumbsSwiper: null,
      modules: [Navigation, FreeMode, Thumbs],
      breakpoints: {
        '@0.20': {
          slidesPerView: 4,
          spaceBetween: 8,
        },
        '@1.00': {
          slidesPerView: 8,
          spaceBetween: 12,
        },
        '@1.50': {
          slidesPerView: 12,
          spaceBetween: 16,
        },
      }
    }
  },
  methods: {
    open(index) {
      this.$refs.modal.open()
    },
    close() {
      this.$refs.modal.close()
    },
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  }
})
</script>

<style lang="scss" scoped>
.modal-slider--swiper {

  // .modal__content {
  .content_header {
    display: flex;
    align-items: center;
    
    gap: 20px;

    .swiperItems {
      .swiper-wrapper {
        .swiper-slide {
          /* Rectangle 18 */

          width: 110px !important;
          height: 72px !important;
          border-radius: 8px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;

          }

          &:not(.swiper-slide-thumb-active) {
            opacity: .6;
          }

          &.swiper-slide-thumb-active {
            opacity: 1;
          }
        }
      }

      .swiper-slide-active1 {
        opacity: 1;
      }

      &.mobile {
        display: none;
      }

      &.desktop {

        display: block;
      }


    }
  }

  .mySwiper2 {
    .swiper-wrapper {
      .swiper-slide {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }


      }

      @media(max-width: 991px) {
        width: calc(100vw - 32px) !important;
        max-width: 520px !important;
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      --_width: 16px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;

      position: absolute;
      width: 48px;
      height: 48px;
      right: 20px;
      top: 50%;


      /* Black & White/Black */
      background: #000000;
      border-radius: 24px;


      &::before {
        content: '';
        // position: absolute;
        width: var(--_width);
        height: 2px;
        background: white;
      }
    }

    .swiper-button-prev {

      &::after {
        position: absolute;

        left: 16px;
        content: '';
        transform: translateX(50%);

        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;

        transform: rotate(135deg);
        -webkit-transform: rotate(135deg); // transform: 180%;
      }
    }

    .swiper-button-next {
      &::after {
        position: absolute;
        right: 16px;
        content: '';

        border: solid white;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;

        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg); // transform: 180%;
      }
    }
  }



  @media (max-width: 991px) {
    gap: 12px;

    .content_header {
      .swiperItems {
        &.mobile {
          display: block;
        }

        &.desktop {
          display: none;
        }
      }
    }

    .mySwiper2 {
      max-width: 520px;
      width: calc(100vw - 32px);
      margin: 0 auto;
      border-radius: 24px;
    }
  }

  @media (min-width: 992px) {
    .content_header {
      margin: 20px 0;
      padding: 0;
    }

    .mySwiper2 {
      height: calc(100vh - 96px);

    }
  }

  // }
}
</style>
