<template>
    <div class="collapse-item" :class="{ isOpen: isOpen }" @click="open()">
        <div class="collapse-header ">
            <span class="title-1">{{ title }}</span>
            <SvgIcon class="icon_svg" :name="isOpen ? 'minus' : 'plus'" />
        </div>

        <Transition v-show="isOpen">
            <div ref="content" class="collapse-body">
                <span class="collapse-content title-3">{{ description }}</span>
            </div>
        </Transition>
    </div>
</template>
<script setup>
import SvgIcon from '@/components/ui/SvgIcon'

const isOpen = ref(false)
const content = ref(null)
const emits = defineEmits(['open'])


const props = defineProps({
    opened: {
        type: Boolean,
        default: false
    },
    title: {
        type: String,
        default: 'title',
    },
    description: {
        type: String,
        default: 'description',
    }
})
watch(
    () => props.opened,
    (newV) => {
        isOpen.value = newV
    },
    { deep: true })
function open() {
    isOpen.value = !isOpen.value
    emits('open', isOpen.value)
}
onMounted(() => {
    isOpen.value = props.opened
})
</script>

<style lang="scss" scoped>
.collapse-item {
    --_bg-1: #FCFBF5;
    background: var(--_bg-1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    border-radius: 12px;
    padding: 20px;
    width: 600px;

    &.isOpen {
        background: #ffffff;
    }

    .collapse-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &:hover {
        background: #ffffff;
        transition: 0.5 all ease;
        cursor: pointer;
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.12);
    }

    @media (max-width:991px) {
        gap: 10px;
        width: 100%;
    }


}
</style>