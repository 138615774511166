<template>
  <section id="statistic-section">
    <div class="section-content container">
      <span class="content-title heading-2">Warum uns wählen?</span>
      <div class="section-reasons">
        <ReasonItem v-for="(item, i) in reasons" :key="i" :item="item" />
      </div>
      <BauButton class="btn-m" btnSuffix="kostenlos" btnText="Angebot einholen" @click="emits('showModal', true)" />
    </div>

  </section>
</template>

<script setup>
import ReasonItem from '~/components/welcome/ReasonItem.vue';
import BauButton from '~/components/ui/BauButton.vue';
import ContactFormModal from '~/components/welcome/ContactFormModal.vue'

import { ref } from 'vue';

const reasons = ref([
  {
    title: 350,
    moreThen: true,
    description: 'Gut durchgeführte Projekte'
  },
  {
    title: 12,
    description: 'Jahre der Erfahrung'
  },
  {
    title: 59022,
    description: 'Tonnen von Beton'
  },
  {
    title: 299,
    description: 'Zufriedene Kunden'
  },
]);
const emits = defineEmits(['showModal'])
</script>

<style lang="scss" scoped>
@use '@/assets/scss/partials/welcome/_statistic-section.scss';
</style>
