<template>
  <section id="qa-section">
    <div class="section-body container">
      <div class="section-title">
        <span class="title-content heading-2">Häufig gestellte Fragen</span>
        <span class="title-description paragraph normal">In unserer FAQ-Sektion finden Sie Antworten auf die häufigsten
          Fragen
          zu unseren Dienstleistungen und Prozessen. Wenn Sie weitere Informationen benötigen, stehen wir Ihnen gerne
          zur Verfügung.</span>
        <div class="action-btns">
          <BauButton btnText="Kontaktieren Sie uns" @click="emits('showModal', true)"></BauButton>
          <BauButton btnType="secondary" :isLink="true" btnLink="https://wa.me/+4915759740593"
            btnText="WhatsApp-Nachricht" @mouseover="onHover" @mouseleave="offHover" :withIcon="true" :icon="icon" />
        </div>
      </div>
      <div class="section-content">
        <CollapseItem v-for="(item, i) in questions" :key="i" :title="item.title" :description="item.description"
          @open="setOpen($event, i)" :opened="item.isOpen">
        </CollapseItem>
      </div>
    </div>
  </section>
</template>

<script setup>
import BauButton from '~/components/ui/BauButton.vue';
import CollapseItem from '~/components/ui/CollapseItem'

const questions = ref([
  {
    title: 'Bieten Sie eine kostenlose Erstberatung an?',
    description: 'Wir verwenden ausschließlich hochwertige Materialien von vertrauenswürdigen Lieferanten, um Langlebigkeit und Zufriedenheit zu gewährleisten.',
    isOpen: false
  },
  {
    title: 'Wie lange dauert eine typische Hausrenovierung?',
    description: 'Wir verwenden ausschließlich hochwertige Materialien von vertrauenswürdigen Lieferanten, um Langlebigkeit und Zufriedenheit zu gewährleisten.',
    isOpen: false
  },
  {
    title: 'Welche Materialien verwenden Sie für Ihre Renovierungen?',
    description: 'Wir verwenden ausschließlich hochwertige Materialien von vertrauenswürdigen Lieferanten, um Langlebigkeit und Zufriedenheit zu gewährleisten.',
    isOpen: false
  },
  {
    title: 'Können Sie bei der Gestaltung meines Renovierungsprojekts helfen?',
    description: 'Wir verwenden ausschließlich hochwertige Materialien von vertrauenswürdigen Lieferanten, um Langlebigkeit und Zufriedenheit zu gewährleisten.',
    isOpen: false
  },
  {
    title: 'Bieten Sie auch Wartungs- und Reparaturdienste nach Abschluss des Projekts an?',
    description: 'Wir verwenden ausschließlich hochwertige Materialien von vertrauenswürdigen Lieferanten, um Langlebigkeit und Zufriedenheit zu gewährleisten.',
    isOpen: false
  },

])
const emits = defineEmits(['showModal'])

const icon = ref({
  position: 'left', name: 'whats-app'
})

function setOpen(e, i) {
  questions.value.forEach((item, idx) => {
    if (idx !== i) {
      item.isOpen = false
    }
    else item.isOpen = true
  })
}
function onHover() {
  setTimeout(() => {
    icon.value.name = 'whats-app-alt'
  }, 150)
}
function offHover() {
  setTimeout(() => {
    icon.value.name = 'whats-app'
  }, 150)
}

</script>

<style lang="scss" scoped>
#qa-section {
  --_bg-3: #efeddd;
  --_bg-1: #FCFBF5;
  --_padding-x: 120px;
  background: var(--_bg-3);
  position: relative;
  display: grid;
  gap: 81.6px;
  // justify-content: center;
  align-items: center;
  padding: var(--_padding-x)0;

  .section-body {
    display: flex;
    gap: 40px;
    padding: var(--_padding-x)0;


    .section-title {
      display: flex;
      width: 560px;
      flex-direction: column;
      gap: 24px;

      .action-btns {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    .section-content {
      display: grid;
      gap: 12px;
    }
  }

  &::before {
    position: absolute;
    content: '';
    width: 120%;
    height: 155px;
    left: -50.27px;
    top: -80px;
    background: #FCFBF5;
    transform: rotate(-3.17deg);
  }

  &::after {
    content: '';
    position: absolute;
    width: 120%;
    height: 2px;
    left: -50.27px;
    top: 75px;
    transform: rotate(-3.17deg);
    background: linear-gradient(256.45deg, #FCFBF5 28.33%, #C1BFB0 97.99%);
  }

  @media (max-width:991px) {

    padding: 160px 0;

    .section-body {
      flex-direction: column;
      gap: 64px;
      padding: 0;

      .section-title {
        span {
          text-align: center;
        }

        .action-btns {
          flex-direction: column;

          :deep(.btn) {
            width: 100%;
          }
        }
      }

      .section-title,
      .section-content {
        width: calc(100vw - 32px);
        max-width: 520px;
        margin: 0 auto;
      }
    }
  }
}
</style>