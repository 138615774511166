<template>
  <div class="service-item">
    <ServiceIcon />
    <span class="item-title title-2">{{ item.title }}</span>
    <span class="item-description default">{{ item.description }}</span>

    <span class="item-title-hover title-1">{{ hoverItem.title }}</span>
    <span class="item-description-hover title-3 text-center">{{ hoverItem.description }}</span>
    <BauButton btnSuffix="kostenlos" btnText="Angebot einholen" btnSize="small" @click="emits('showModal', true)"/>

  </div>
</template>

<script setup>
import ServiceIcon from '~/components/ui/service/ServiceIcon'
import BauButton from '~/components/ui/BauButton.vue';
const props = defineProps({
  item: {
    type: Object,
    default() {
      return {
        title: 'Fenster- und Türeinbau',
        description: 'Wir verbessern die Wärme- und Ästhetikisolierung Ihres Zuhauses durch den Einbau hochwertiger Fenster und Türen.',

      }
    }
  },
  hoverItem: {
    type: Object,
    default() {
      return {
        title: 'Angebot einholen',
        description: 'Sichern Sie sich jetzt unser exklusives Sonderangebot!'
      }
    }
  }
})
const emits = defineEmits(['showModal'])

</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/service-item.scss'
</style>