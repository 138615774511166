<template>
  <section id="review-section">
    <div class="section-body container">
      <div class="section-title">
        <span class="title-content heading-2">Kunden bewertungen sagen alles</span>
        <span class="title-description paragraph normal">Erfahren Sie aus den Bewertungen unserer zufriedenen Kunden,
          warum wir
          die beste Wahl für Ihre Bauprojekte sind. Ihre positiven Erfahrungen sprechen für unsere Qualität und
          Zuverlässigkeit.</span>
        <div class="action-btns">
          <BauButton btnText="Kontaktieren Sie uns" @click="emits('showModal', true)"></BauButton>
          <BauButton btnType="secondary" btnText="Finden Sie uns auf Google Maps" :withIcon="true" :isLink="true"
            btnLink="https://www.google.de/maps/search/Erlenstrasse+11,+32549+%D0%91%D0%B0%D0%B4-%D0%AD%D0%B9%D0%BD%D1%85%D0%B0%D1%83%D0%B7%D0%B5%D0%BD-%D0%91%D0%B0%D0%B4+%D0%AD%D0%BA%D1%81%D0%B5%D0%BD/@52.2325312,8.8130651,16.5z?entry=ttu"
            @mouseover="onHover" @mouseleave="offHover" :icon="icon"></BauButton>
        </div>
      </div>
      <div class="section-content">
        <Swiper :modules="modules" :direction="'vertical'" :breakpoints="breakpoints" :spaceBetween="12"
          :slidesPerView="5" :loop="true">
          <SwiperSlide v-for="(item, i) in reviews" :key="i">
            <ReviewItem :item="item" />
          </SwiperSlide>
        </Swiper>

        <ReviewItem class="mobile-item" :item="item" v-for="(item, i) in reviewsMobile" :key="i" />
      </div>
    </div>
  </section>
</template>

<script setup>
import ReviewItem from '~/components/welcome/ReviewItem'

const reviews = ref([
  {
    author: 'Petra Wagner',
    rating: 1,
    review: 'Ihr Team war äußerst zuverlässig und professionell. Sie haben das Projekt termingerecht und zur vollsten Zufriedenheit abgeschlossen. Wir sind sehr beeindruckt!'
  },
  {
    author: 'Sabine Fischer',
    rating: 2,
    review: 'Die Dienstleistungen waren schnell und professionell. Wir waren beeindruckt von der Sorgfalt und der Qualität der Arbeit.'
  },
  {
    author: 'Michael Schmidt',
    rating: 3,
    review: 'Wir sind absolut begeistert von den Ergebnissen! Unser Haus erstrahlt jetzt in neuem Glanz. Vielen Dank an das fantastische Team!'
  },
  {
    author: 'Petra Wagner',
    rating: 4,
    review: 'Ihr Team war äußerst zuverlässig und professionell. Sie haben das Projekt termingerecht und zur vollsten Zufriedenheit abgeschlossen. Wir sind sehr beeindruckt!'
  },
  {
    author: 'Klaus Muller',
    rating: 5,
    review: 'Wir sind absolut begeistert von den Ergebnissen! Unser Haus erstrahlt jetzt in neuem Glanz. Vielen Dank an das fantastische Team!'
  },
  {
    author: 'Petra Wagner',
    rating: 1,
    review: 'Ihr Team war äußerst zuverlässig und professionell. Sie haben das Projekt termingerecht und zur vollsten Zufriedenheit abgeschlossen. Wir sind sehr beeindruckt!'
  },
  {
    author: 'Sabine Fischer',
    rating: 2,
    review: 'Die Dienstleistungen waren schnell und professionell. Wir waren beeindruckt von der Sorgfalt und der Qualität der Arbeit.'
  },
  {
    author: 'Michael Schmidt',
    rating: 3,
    review: 'Wir sind absolut begeistert von den Ergebnissen! Unser Haus erstrahlt jetzt in neuem Glanz. Vielen Dank an das fantastische Team!'
  },
  {
    author: 'Petra Wagner',
    rating: 4,
    review: 'Ihr Team war äußerst zuverlässig und professionell. Sie haben das Projekt termingerecht und zur vollsten Zufriedenheit abgeschlossen. Wir sind sehr beeindruckt!'
  },
  {
    author: 'Klaus Muller',
    rating: 5,
    review: 'Wir sind absolut begeistert von den Ergebnissen! Unser Haus erstrahlt jetzt in neuem Glanz. Vielen Dank an das fantastische Team!'
  },
])
const reviewsMobile = ref([])
const emits = defineEmits(['showModal'])

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import BauButton from "~/components/ui/BauButton.vue";

const icon = ref({
  position: 'left', name: 'map'
})

const modules = ref([Autoplay])
function onHover() {
  setTimeout(() => {
    icon.value.name = 'map-alt'
  }, 150)
}
function offHover() {
  setTimeout(() => {
    icon.value.name = 'map'
  }, 150)
}
const breakpoints = ref({
  '992': {
    autoplay: {
      enabled: true,
      delay: 1000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },

  }
})

onMounted(() => {
  reviewsMobile.value = reviews.value.slice(0, 5)
})
</script>

<style lang="scss" scoped>
#review-section {
  --_bg-3: #efeddd;
  --_bg-1: #FCFBF5;
  background: var(--_bg-1);
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;

  .section-body {
    display: flex;
    gap: 77px;
    position: relative;

    .section-title {
      display: flex;
      width: 700px;
      flex-direction: column;
      gap: 24px;
      margin-top: 80px;

      .action-btns {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }

    .section-content {
      width: 423px;
      height: 658px;
      position: relative;



      .swiper {
        height: 100%;
      }

    }
  }

  &::before {
    position: absolute;
    content: '';
    width: 120%;
    height: 155px;
    left: -50.27px;
    top: -80px;
    background: var(--_bg-1);
    transform: rotate(3.17deg);

  }

  @media (max-width: 991px) {
    padding: 80px 0;

    .section-body {
      flex-direction: column;
      gap: 64px;
      padding: 0;

      .section-title {
        span {
          text-align: center;
        }

        .action-btns {
          flex-direction: column;

          :deep(.btn) {
            width: 100%;
          }
        }
      }

      .section-content {
        .swiper {
          display: none;
        }

        height: auto;

        .review-item:first-child,
        .review-item:last-child {
          opacity: 1;
        }
      }

      .section-title,
      .section-content {
        width: calc(100vw - 32px);
        max-width: 520px;
        margin: 0 auto;
      }
    }
  }

  @media (min-width:992px) {
    .section-body {
      .section-content {
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5));
        mask-size: 100% 80%;
        mask-repeat: no-repeat;
        mask-position: left top, left bottom;

        .mobile-item {
          display: none;
        }
      }
    }

  }
}
</style>