<template>
  <section id="service-section" class="container">
    <div class="section-title">
      <div class="title-content">
        <span>
          <span class="dark heading-2">Erkunden</span>
          <span class="heading-2 "> Unsere
            Dienstleistungen</span>
        </span>

      </div>


      <div class="title-description paragraph normal">Entdecken Sie unsere Dienstleistungen für Immobilienreparaturen
        und
        Renovierungen, um Ihr Haus oder Ihre Wohnung in den Raum Ihrer Träume zu verwandeln. Wählen Sie unsere
        Professionalität für qualitativ hochwertige Ergebnisse und Aufmerksamkeit zum Detail.</div>
    </div>
    <div class="section-content container">
      <ServiceItem v-for="(service, i) in services" :key="i" :item="service" @showModal="emits('showModal', $event)" />
    </div>
  </section>
</template>

<script setup>
import ServiceItem from '~/components/welcome/ServiceItem';
const services = ref([
  { title: 'Innenausbau', description: 'Wir verwandeln das Innere Ihres Hauses oder Ihrer Wohnung in einen modernen und komfortablen Raum durch maßgeschneiderte und sorgfältig verwaltete Renovierungen.' },
  { title: 'Dachreparatur', description: 'Wir gewährleisten die Integrität und Langlebigkeit Ihres Daches durch professionelle Reparaturen und hochwertige Materialien.' },
  { title: 'Sanitärinstallation und - reparatur', description: 'Wir bieten umfassende Dienstleistungen für die Installation und Reparatur von Sanitäreinrichtungen, um die optimale Funktionalität und den Komfort im Bad und in der Küche zu gewährleisten.' },
  { title: 'Außenrenovierung', description: 'Wir erneuern das äußere Erscheinungsbild Ihres Hauses durch ästhetische und funktionale Renovierungen, einschließlich Fassadenanstrich und Reparaturen der Außenböden.' },
  { title: 'Parkettrestaurierung', description: 'Wir stellen die natürliche Schönheit von Holzböden durch professionelle Restaurierung und langanhaltende Schutzbehandlungen wieder her.' },
  { title: 'Fenster- und Türeinbau', description: 'Wir verbessern die Wärme- und Ästhetikisolierung Ihres Zuhauses durch den Einbau hochwertiger Fenster und Türen.' },
  { title: 'Trockenbauarbeiten', description: 'Unsere Trockenbauexperten bieten maßgeschneiderte Lösungen für Wand- und Deckenverkleidungen, um Ihren Räumen ein modernes und ansprechendes Aussehen zu verleihen.' },
  { title: 'Innenausbau', description: 'Wir verwandeln das Innere Ihres Hauses oder Ihrer Wohnung in einen modernen und komfortablen Raum durch maßgeschneiderte und sorgfältig verwaltete Renovierungen.' },

])
const emits = defineEmits(['showModal'])

</script>

<style lang="scss" scoped>
@use '~/assets/scss/partials/welcome/_service-section.scss';
</style>