<script lang="ts">
import {defineComponent} from 'vue';
import CountUp from 'vue-countup-v3';

export default defineComponent({
  components: {
    CountUp,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
});

</script>

<template>
  <div class="reason-item">
    <div class="counter">
      <ClientOnly>
        <CountUp
            class="item-title heading-3 statistic"
            :start-val="1"
            :end-val="item.title"
            :duration="5"
            :options="{
                    useEasing: false,
                    useGrouping: true,
                    separator: ' ',
                    enableScrollSpy: true,
                    scrollSpyOnce: true,
                  }"
        />
        <span class="heading-3 statistic"
              v-show="item.moreThen">+</span>
      </ClientOnly>

    </div>
    <span class="item-description paragraph normal text-center">{{ item.description }}</span>
  </div>
</template>

<style lang="scss" scoped>
@use '@/assets/scss/components/reason-item.scss';
</style>
