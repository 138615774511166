<template>
  <div class="work-item" :style="itemStyle" @mouseover="isHover = true" @mouseleave="isHover = false">
    <img :src="item.url" alt="item_img">
    <BauButton :icon="icon" btnText="Uhr" :lightMode="true" :withIcon="true" @mouseover="onHover" @mouseleave="offHover"
      btnType="secondary" @click="emits('showModal')" />
  </div>

</template>

<script setup>
import BauButton from '~/components/ui/BauButton.vue';

const emits = defineEmits(['showModal'])
const props = defineProps({
  item: {
    type: Object,
    default() {
      return { url: '' }
    }
  }
})
const isHover = ref(false)
const icon = ref(
  { name: 'eye-alt', position: 'left' }
)
const itemStyle = computed(() => {
  if (isHover) {
    return {
      background: `linear-gradient(0deg, rgba(217, 184, 13, 0.6), rgba(217, 184, 13, 0.6)), url(${props.item.url})`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }
  }
  return {}
})
function onHover() {
  setTimeout(() => {
    icon.value.name = 'eye'
  }, 150)
}
function offHover() {
  setTimeout(() => {
    icon.value.name = 'eye-alt'
  }, 150)
}



</script>

<style lang="scss" scoped>
.work-item {
  width: 291px;
  height: 300px;
  border-radius: 24px;

  transition: 0.5s all ease-in;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;

  }

  .btn {
    display: none;
  }

  &:hover {
    // --_lg-1: linear-gradient(0deg, rgba(217, 184, 13, 0.6), rgba(217, 184, 13, 0.6)), url('2151317340.jpg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.5s all ease-out;

    background: var(--_lg-1);

    img {
      display: none;
    }

    .btn {
      display: flex
    }

  }
}
</style>