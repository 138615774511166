<script>
import WelcomeSection from '~/components/welcome/sections/WelcomeSection'
import StatisticSection from '~/components/welcome/sections/StatisticSection'
import ServiceSection from '~/components/welcome/sections/ServiceSection'
import TeamSection from '~/components/welcome/sections/TeamSection'
import WorkSection from '~/components/welcome/sections/WorkSection.vue'
import QASection from '~/components/welcome/sections/QASection.vue'
import ReviewSection from '~/components/welcome/sections/ReviewSection.vue'
import ContactSection from '~/components/welcome/sections/ContactSection.vue'
import ContactFormModal from '~/components/welcome/ContactFormModal.vue'
import TheFooter from "../components/TheFooter.vue";

export default defineComponent({
  components: {
    WelcomeSection,
    StatisticSection,
    ServiceSection,
    TeamSection,
    WorkSection,
    QASection,
    ReviewSection,
    ContactSection,
    ContactFormModal,
    TheFooter
  },
  data() {
    return {showModal: false}

  },
  setup() {
    useHead({
      title: 'Professionelle Lösungen für die Reparatur Ihres Hauses oder Ihrer Wohnung',
      htmlAttrs: {
        lang: 'de',
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Mit einem engagierten Team und Liebe zum Detail bieten wir professionelle Lösungen für die Komplettrenovierung Ihres Hauses oder Ihrer Wohnung. Wählen Sie uns für garantierte Qualität und Effizienz!'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'Renovierung, Hausrenovierung, Wohnungrenovierung, Renovierungsfirma, Renovierungsdienstleistungen'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://dobobau.de',
        },
        {rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
        {
          rel: 'canonical',
          href: 'https://dobobau.de',
        }
      ]
    });
    useServerSeoMeta({
      title: 'Professionelle Lösungen für die Reparatur Ihres Hauses oder Ihrer Wohnung',
      ogTitle: 'Professionelle Lösungen für die Reparatur Ihres Hauses oder Ihrer Wohnung',
      description: 'Mit einem engagierten Team und Liebe zum Detail bieten wir professionelle Lösungen für die Komplettrenovierung Ihres Hauses oder Ihrer Wohnung. Wählen Sie uns für garantierte Qualität und Effizienz!',
      ogDescription: 'Mit einem engagierten Team und Liebe zum Detail bieten wir professionelle Lösungen für die Komplettrenovierung Ihres Hauses oder Ihrer Wohnung. Wählen Sie uns für garantierte Qualität und Effizienz!',
      ogType: 'website',
      ogUrl: 'https://dobobau.de',
      ogImage: '/logo.svg',
      twitterCard: 'summary_large_image',
      twitterTitle: 'Midavco - CRM and sales management',
      twitterDescription: 'Mit einem engagierten Team und Liebe zum Detail bieten wir professionelle Lösungen für die Komplettrenovierung Ihres Hauses oder Ihrer Wohnung. Wählen Sie uns für garantierte Qualität und Effizienz!',
      twitterImage: 'https://dobobau.de/og-image.png',
      robots: 'index, follow',
    })
  }
})


</script>

<template>
  <div class="page ">
    <WelcomeSection/>
    <StatisticSection @showModal="showModal = $event"/>
    <ServiceSection @showModal="showModal = $event"/>
    <TeamSection @showModal="showModal = $event"/>
    <WorkSection/>
    <QASection @showModal="showModal = $event"/>
    <ReviewSection @showModal="showModal = $event"/>
    <ContactSection/>
    <TheFooter customClass="white"/>
    <ContactFormModal ref="contactModal" :isOpen="showModal" @close="showModal = $event"/>
  </div>
</template>

<style scoped lang="scss">
@use '../assets/scss/pages/_welcome';
</style>
