<template>
  <section id="work-section">
    <div class="section-title container">
      <div class="title-content heading-2">
        <span>Unsere </span>
        <span class="heading-2 text-1">Arbeiten</span>
        <span> ansehen</span>
      </div>
      <span class="title-description paragraph normal">
        Entdecken Sie die Qualität unserer Arbeiten durch unsere abgeschlossenen Projekte. Jedes Projekt zeigt unser
        Engagement für Exzellenz und unsere Liebe zum Detail in der Bauindustrie.
      </span>
    </div>
    <div class="section-content container">
      <WorkItem v-for="(work, i) in works" :key="i" :item="work" @showModal="$refs.galleryModal.open(i)" />
    </div>

    <GalleryModal :works="works" ref="galleryModal" />

    <ImageSlider :items="works" class="swiper-mobile" />

  </section>
</template>

<script>
import WorkItem from '~/components/welcome/WorkItem.vue';
import GalleryModal from '~/components/welcome/GalleryModal.vue';

export default defineComponent({
  components: {
    WorkItem,
    GalleryModal,
  },
  data() {
    return {
      works: [
        { url: '/img/welcome/work1.jpg' },
        { url: '/img/welcome/work2.jpg' },
        { url: '/img/welcome/work3.jpg' },
        { url: '/img/welcome/work4.jpg' },
        { url: '/img/welcome/work1.jpg' },
        { url: '/img/welcome/work2.jpg' },
        { url: '/img/welcome/work3.jpg' },
        { url: '/img/welcome/work4.jpg' },
        { url: '/img/welcome/work1.jpg' },
        { url: '/img/welcome/work2.jpg' },
        { url: '/img/welcome/work3.jpg' },
        { url: '/img/welcome/work4.jpg' },
      ],
      galleryModal: null,
    };
  }
})
</script>

<style lang="scss" scoped>
@use '@/assets/scss/partials/welcome/work-section.scss';
</style>